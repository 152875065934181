<template>
  <CRow>
    <CModal
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary"
        :hide-footer="true"
    >

      <img alt="Registration Document" class="imageFormat" :src="viewImage" style="float: left;width: 100%"/>


      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton
        >
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton
        >
      </template>
    </CModal>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.inquiry.crud.view }}</strong>
        </CCardHeader>
        <CCardBody>
          <table class="table table-bordered">
            <tr>
              <th>{{ $lang.inquiry.form.name }}</th>
              <td>{{ inquiry.name }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.mobile }}</th>
              <td>{{ inquiry.contactCountryCode }} {{ inquiry.mobile }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.email }}</th>
              <td>{{ inquiry.email }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.budget }}</th>
              <td>{{ inquiry.budget }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.cityName }}</th>
              <td>{{ inquiry.cityName }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.countryName }}</th>
              <td>{{ inquiry.countryName }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.description }}</th>
              <td>{{ inquiry.description }}</td>
            </tr>

            <tr>
              <th>{{ $lang.inquiry.form.inquiryIndustry }}</th>
              <td>{{ inquiry.inquiryIndustry }}</td>
            </tr>
            <tr>
              <th>{{ $lang.inquiry.form.yourIndustry }}</th>
              <td>{{ inquiry.yourIndustry }}</td>
            </tr>
            <tr>
              <th>{{ $lang.common.form.created_at }}</th>
              <td>{{ dateTimeUTCtoLocalFormat(inquiry.createdAt) }}</td>
            </tr>
            <tr>
              <th>{{ $lang.common.form.updated_at }}</th>
              <td>{{ dateTimeUTCtoLocalFormat(inquiry.updatedAt) }}</td>
            </tr>
          </table>
          <b>Services</b>
          <table class="table table-bordered">
            <tr v-for="(item, index) in inquiry.subindustrys">
              <td>
                {{ item.industry }}
              </td>
            </tr>
          </table>
          <b>Attachments</b>
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <div class="form-group" id="people">
                <div class="verify-docs">
                  <div
                      v-for="(docs, index) in inquiry.attachment"
                      :key="docs.id"
                      class="doc-box"
                  >
                    <div class="card card-file media">
                      <div class="card-body text-center">
                        <div v-if="docs.type === 'image'">
                          <img
                              @click="open(docs.url)"
                              alt="Image"
                              :src="docs.url"
                              class="imageDimenstion"
                          />
                        </div>
                        <div v-else-if="docs.type === 'pdf'">
                          <img
                              :src="defaultPdfImage"
                              alt="Default Image"
                              v-on:click="viewPdf(docs.url)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else-if="docs.type === 'doc'">
                          <img
                              :src="defaultDocImage"
                              alt="Default Image"
                              v-on:click="viewDoc(docs.id)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else-if="docs.type === 'xls'">
                          <img
                              :src="defaultXlsImage"
                              alt="Default Image"
                              v-on:click="viewXls(docs.id)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                        <div v-else>
                          <img
                              :src="defaultDocImage"
                              alt="Default Image"
                              v-on:click="viewDoc(docs.id)"
                              class="imageDimenstion mx-auto d-block"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="danger" size="sm" @click="cancel">
            <CIcon name="cil-ban"/>
            {{ $lang.buttons.general.crud.cancel }}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {inquiry} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "InquiryView",
  mixins: [Mixin],
  data() {
    return {
      submitted: false,
      dismissCountDown: 0,
      self: this,
      module: inquiry,
      largeModal: false,
      viewImage: "",
      editId: this.$route.params.id,
      defaultDocImage: localStorage.getItem('defaultDocImage'),
      defaultPdfImage: localStorage.getItem('defaultPdfImage'),
      defaultXlsImage: localStorage.getItem('defaultXlsImage'),
      inquiry: {
        budget: "",
        contactCountryCode: "",
        mobile: "",
        name: "",
        countryName: "",
        cityName: "",
        description: "",
        email: "",
        subindustrys: "",
        attachment: "",
        yourIndustry: "",
        inquiryIndustry: "",
        createdAt: "",
        updatedAt: "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get(this.viewUrlApi(this.module, self.editId))
        .then((response) => {
          let inquiry = response.data.data;
          self.inquiry.budget = inquiry.budget;
          self.inquiry.contactCountryCode = inquiry.contactCountryCode;
          self.inquiry.mobile = inquiry.mobile;
          self.inquiry.name = inquiry.name;
          self.inquiry.countryName = inquiry.countryName;
          self.inquiry.cityName = inquiry.cityName;
          self.inquiry.description = inquiry.description;
          self.inquiry.email = inquiry.email;
          self.inquiry.attachment = inquiry.attachment;
          self.inquiry.subindustrys = inquiry.subIndustry;
          self.inquiry.yourIndustry = inquiry.yourIndustry;
          self.inquiry.inquiryIndustry = inquiry.inquiryIndustry;
          self.inquiry.updatedAt = inquiry.updatedAt;
          self.inquiry.createdAt = inquiry.createdAt;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "inquiry"});
    },
    viewPdf(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/pdf/${id}`});
      window.open(id, "_blank");
    },
    viewDoc(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/doc/${id}`});
      window.open(id, "_blank");
    },
    viewXls(id) {
      // let self = this;
      // let routeData = this.$router.resolve({path: `/viewer/xls/${id}`});
      window.open(id, "_blank");
    },
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
    documentsIndex: function (index) {
      return index + 1;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
